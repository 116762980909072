@charset "utf-8";

@import "variables";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;font-weight:inherit;font-style:normal;vertical-align:baseline;}
body{-webkit-text-size-adjust:100%;line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ul,ol{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
ins{background-color:#ff9;color:#000;text-decoration:none}
mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
input,select{vertical-align:middle}
a{color:inherit;text-decoration:none;outline:none;}
img{-ms-interpolation-mode: bicubic;}


::selection{background:#000;color:#fff;}
::-moz-selection{background:#000;color:#fff;}

html{
	position: relative;
	overflow-x: hidden;
}
body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: $fontSize;
	font-family: $fontBase;
	// font-weight: lighter;
	line-height: $lineHeight;
	letter-spacing: 0;
	color: $textColor;
	background-color: #fff;
	// position: relative;
	overflow-x: hidden;
	// -webkit-text-stroke-width: 0.1px;
}

img,
svg{
	width: 100%;
	height: auto;
}

.inner{
	position: relative;
	z-index: 1;
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 15px;
}
.sec{
	.inner{
		max-width: 750px;
	}
}
.sec.sec-gray{
	background-color: #F0F0F0;
}

.pc{display: none;}
.smt{display: block;}

ul{
	letter-spacing: -.4em;
	li{
		letter-spacing: normal;
	}
}

h1{
	display: none !important;
}

/* font-family */
.f_amiri{
	font-family: $fontEn;
}
.f_work{
	font-family: $fontWork;
	font-weight: 600;
}
.f_robot{
	font-family: $fontRobot;
	font-weight: 700;
}
.f_goth{
	font-family: $fontGothic;
}


#loading{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color: #fff;
}

#loader{
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9;
	color: #9d9d9d;
	font-size: 16px;
	letter-spacing: 3px;
	transform: translate(-50%, -50%);
}

header{
	.inner{
		padding: 0 10px;
	}
}
.logo{
	width: 70%;
	max-width: 260px;
	padding: 3% 0;
	img{
		width: 75%;
		vertical-align: middle;
	}
	img[alt="symbol"]{
		width: 20%;
		margin: 0 5% 0 0;
		transform-style: preserve-3d;
		// animation: ROTATE 5s linear infinite;
	}
}
/*
@keyframes ROTATE {
	0% {transform: rotate(0deg)}
	100% {transform: rotate(360deg)}
}
*/

#en-lead{
	font-size: 11px;
	font-style: italic;
	line-height: 1.8;
	letter-spacing: 1px;
	margin: 0 5px 5%;
}

#connect{
	text-align: right;
	letter-spacing: -.4em;
	margin: 0 0 7%;
	padding: 0 5px;
	line-height: 1;
}
#contact,
#withUs{
	letter-spacing: normal;
	display: inline-block;
	vertical-align: middle;
}

#contact{
	margin: 0 3px 0 0;
	a{
		display: block;
		letter-spacing: 2px;
		padding: 8px 30px;
		border: 1px solid #000;
	}
}
#withUs{
	ul{
		li{
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 0 10px;
			a{
				display: block;
				i{
					font-size: 32px;
				}
			}
		}
	}
}


#main-view{
	line-height: 0;
}

.p-nav{
	max-width: 880px;
	margin: 10px auto;
	line-height: 1.8;
	ul{
		li{
			display: inline-block;
			vertical-align: middle;
			width: 50%;
			box-sizing: border-box;
			a{
				position: relative;
				text-align: center;
				display: block;
				font-size: 13px;
				letter-spacing: 2px;
				padding: 10px 0;
			}
		}
		li:nth-of-type(odd){
			a:before{
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 0;
				width: 1px;
				height: 14px;
				background-color: #000;
				transform: translateY(-50%) rotate(25deg);
				transform-origin: right bottom;
			}
		}
	}
}


.sec{
	padding: 8vw 0 0;
	.sec-head{
		text-align: center;
		padding: 0 0 5vw;
	}
	.sec-body{
		.sub-sec{
			position: relative;
			text-align: center;
			margin: 0 0 15%;
			img.sec-logo{
				width: 70%;
				max-width: 200px;
			}
			.sub-sec-lead{
				display: inline-block;
				margin: 5% 0 0;
			}
			.wm-rl{
				line-height: 2.4;
				letter-spacing: .1em;
			}
		}
		.sub-sec:last-of-type{
			margin: 0;
		}
	}
}
.sec.sec-gray{
	padding: 10vw 0;
}

.sec01{
	padding: 5vw 0 0;
}


/* ----- key ----- */
/* key-lead */
.key-lead{
	font-size: 16px;
	line-height: 1.8;
	letter-spacing: .4em;
	margin: 0 0 10px;
	position: relative;
	top: 50px;
	opacity: 0;
	filter: blur(4px);
	transition: all 2s;
	span{
		display: inline-block;
	}
}
.key-mask + .key-lead{
	margin-top: 5%;
	span{
		font-size: .87em;
	}
}

.key-lead.on{
	top: 0;
	opacity: 1;
	filter: blur(0px);
}

/* key-img */
.key-img{
	line-height: 0;
	position: relative;
	overflow: hidden;
	span{
		img{
			opacity: 0;
			transform: scale(1.1);
		}
	}
	span:before,
	span:after{
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		background-color: #000;
	}
	span:before{
		right: 0;
		width: 0;
		height: 1px;
	}
	span:after{
		left: 0;
		width: 1px;
		height: 0;
	}
}
.key-img:before,
.key-img:after{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	background-color: #000;
}
.key-img:before{
	left: 0;
	width: 0;
	height: 1px;
}
.key-img:after{
	right: 0;
	width: 1px;
	height: 0;
}
/* on */
.key-img.on{
	span{
		img{
			animation: SHOWIMG 3s ease-out forwards;
		}
	}
}
.key-img.on:before{
	animation: DRAW1 2s linear forwards;
}
.key-img.on:after{
	animation: DRAW2 2s linear forwards;
}
.key-img.on{
	span:before{
		animation: DRAW3 2s linear forwards;
	}
	span:after{
		animation: DRAW4 2s linear forwards;
	}
}
@keyframes DRAW1 {
	0%{width: 0;}
	15%{width: 100%;}
	60%{width: 100%;background-color: #000;}
	100%{width: 100%;background-color: transparent;}
}
@keyframes DRAW2 {
	0%{height: 0;}
	16%{height: 0;}
	30%{height: 100%;}
	60%{height: 100%;background-color: #000;}
	100%{height: 100%;background-color: transparent;}
}
@keyframes DRAW3 {
	0%{width: 0;}
	31%{width: 0;}
	45%{width: 100%;}
	60%{width: 100%;background-color: #000;}
	100%{width: 100%;background-color: transparent;}
}
@keyframes DRAW4 {
	0%{height: 0;}
	46%{height: 0;}
	60%{height: 100%;background-color: #000;}
	100%{height: 100%;background-color: transparent;}
}
@keyframes SHOWIMG {
	0%{opacity: 0;}
	50%{opacity: 0;transform: scale(1.1);}
	100%{opacity: 1;transform: scale(1);}
}

/* key-mask */
.key-mask{
	line-height: 0;
	svg{
		width: 80%;
		max-width: 460px;
	}
}

/* key-sent - p */
.key-sent{
	p{
		opacity: 0;
		filter: blur(4px);
		transition: all 2s;
	}
	p.on{
		opacity: 1;
		filter: blur(0px);
	}
}

/* key-svg */
.key-svg{
	line-height: 0;
}
.key-svg.gray.on{
	svg{
		path{
			stroke:#000;
		    fill:transparent;
		    stroke-width:1;    
		    stroke-dasharray: 3000;
		    stroke-dashoffset:3000;
			animation: DASH_GRAY 5s linear 0s forwards; // or 3s
		}
	}
}
@keyframes DASH_GRAY{
	0%{stroke-dashoffset:3000;stroke:#000;fill: transparent;}
	60%{stroke-dashoffset:0;stroke-width:1;stroke:#b2b2b2;fill: transparent;}
	100%{stroke-dashoffset:0;stroke-width:0;stroke:transparent;fill: #b2b2b2;}
}

.key-svg.lightgray.on{
	svg{
		path{
			stroke:#000;
		    fill:transparent;
		    stroke-width:1;    
		    stroke-dasharray: 3000;
		    stroke-dashoffset:3000;
			animation: DASH_LIGHTGRAY 5s linear 0s forwards; // or 3s
		}
	}
}
@keyframes DASH_LIGHTGRAY{
	0%{stroke-dashoffset:3000;stroke:#000;fill: transparent;}
	60%{stroke-dashoffset:0;stroke-width:1;stroke:#b2b2b2;fill: transparent;}
	100%{stroke-dashoffset:0;stroke-width:0;stroke:transparent;fill: #FBFBFB;}
}


/* btn */
a.btn,
button.btn{
	cursor: pointer;
	position: relative;
	display: inline-block;
	width: 90%;
	max-width: 260px;
	font-size: 12px;
	letter-spacing: 2px;
	font-family: $fontGothic;
	padding: 10px;
	margin: auto;
	border: 1px solid #000;
	box-sizing: border-box;
}
a.btn:before,
button.btn:before{
	content: '';
	display: block;
	width: 6px;
	height: 6px;
	position: absolute;
	top: 50%;
	left: 15px;
	border-top: 1px solid #000;
	border-right: 1px solid #000;
	transform: translateY(-50%) rotate(45deg);
}
a.btn:after,
button.btn:after{
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 5px;
	right: -6px;
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
	transition: all .2s;
}
a.btn:hover:after,
button.btn:hover:after{
	top: 0;
	right: -1px;
}


footer{
	.f-group{
		padding: 10% 0 5%;
	}
	.logo{
		text-align: center;
		margin: auto;
		padding: 0 0 5%;
		img[alt="symbol"]{
			width: 30%;
			max-width: 86px;
			margin: 0 0 10px;
		}
	}
}
#copyright{
	text-align: center;
	font-size: 12px;
	letter-spacing: 1px;
	font-family: $fontGothic;
	padding: 10px;
	background-color: #f0f0f0;
}

#pagetop{
	display: none;
	cursor: pointer;
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 8;
	background-color: #fff;
	width: 60px;
	height: 60px;
	span{
		display: block;
		position: absolute;
		top: 55%;
		left: 50%;
		width: 12px;
		height: 12px;
		border-top: 1px solid #000;
		border-right: 1px solid #000;
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}


/* ------------------------ lower */
body#lower{
	
}


@media screen and (min-width: 600px) {
	#en-lead{
		font-size: 12px;
		letter-spacing: 1.5px;
	}
	
	#en-lead{
		margin: 0 5px 3%;
	}
	#connect{
		margin: 0 0 3%;
	}
	#contact{
		a{
			padding: 10px 50px;
		}
	}
	#withUs{
		ul{
			li{
				margin: 0 0 0 15px;
				a{
					i{
						font-size: 36px;
					}
				}
			}
		}
	}

/* ----- key ----- */
	.key-lead{
		font-size: 20px;
	}

	footer{
		.f-group{
			padding: 5% 0 2.5%;
		}
	}


/* ------------------------ lower */
	body#lower{
		header{

		}
		#connect{
			margin: 0;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
		}
	}


}


@media screen and (min-width: 800px) {
	.pc{display: block;}
	.smt{display: none;}
	
	.p-nav{
		margin: 20px auto;
		ul{
			white-space: nowrap;
			text-align: center;
			li{
				width: auto;
				a{
					position: relative;
					padding: 10px 20px;
				}
				a:after{
					content: '';
					display: block;
					position: absolute;
					left: 50%;
					width: 6px;
					height: 6px;
					border-top: 1px solid #000;
					border-right: 1px solid #000;
					opacity: 0;
					transition: all .3s;
				}
				a:hover:after{
					opacity: 1;
				}
			}
			li:nth-of-type(even){
				a:before{
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 0;
					width: 1px;
					height: 14px;
					background-color: #000;
					transform: translateY(-50%) rotate(25deg);
					transform-origin: right bottom;
				}
			}
			li:last-of-type{
				a:before{
					display: none;
				}
			}
		}
	}
	.p-nav.downward{
		ul{
			li{
				a:after{
					bottom: -10px;
					transform: rotate(135deg);
				}
				a:hover:after{
					bottom: 0;
				}
			}
		}
	}
	.p-nav.upward{
		ul{
			li{
				a:after{
					top: -10px;
					transform: rotate(-45deg);
				}
				a:hover:after{
					top: 0;
				}
			}
		}
	}


	.sec{
		.sec-body{
			.sub-sec{
				.sub-sec-lead{
					margin: 0;
				}
				.wm-rl{
					text-align: left;
					font-size: 15px;
					letter-spacing: .2em;
					line-height: 3.3;
					writing-mode: vertical-rl;
					-ms-writing-mode: tb-rl;
					-webkit-writing-mode:vertical-rl;
					p{
						white-space: nowrap;
					}
				}
			}
		}
	}


/* ----- key ----- */
	.key-lead{
		font-size: 22px;
	}

	#pagetop{
		width: 80px;
		height: 80px;
	}

}


@media screen and (min-width: 1000px) {
	.p-nav{
		ul{
			li{
				a{
					font-size: 14px;
					padding: 10px 30px;
				}
			}
		}
	}


/* ----- key ----- */
	.key-lead{
		font-size: 24px;
	}

}


/* max */
@media screen and (min-width: 1280px) {
	.sec{
		padding: 110px 0 0;
		.sec-head{
			padding: 0 0 70px;
		}
	}
	.sec.sec-gray{
		padding: 110px 0;
	}

	.sec01{
		padding: 65px 0 0;
	}

}
